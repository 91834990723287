.App {
  padding: 0% 5% 10% 5%;
}

.App.container {
  width: 100%;
  max-width: 90;
  margin:auto;
  container-name: app-container;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: rgb(5, 5, 0);
  width: 100%;
  background-image: url('../public/CR-header-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0% 5% 0% 5%; 
}

.App-header.container {
  width: 100%;
  max-width: 96%;
  margin:auto;
  container-name: app-header-container;

}

.App-header.h1 {
  font-weight: bolder;
  text-align: center;
}

.App-header.h2 {
  font-weight: bold;
  text-align: center;
}

.App-banner {  
  font-size: calc(10px + 2vmin);
  font-family:'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  align-content: left;
  padding: 0% 5% 1em 5%;
  column-count: 2;
  text-align: left;
  column-rule: 2px solid lightgreen;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  text-align: center;
}

.App-footer.container {
  width: 100%;
  max-width: 90;
  container-name: app-footer-container;
  display: flex;
  justify-content: center;
}

.Carousel {
  align-items: center;
  justify-content: center;
  padding: 0% 5% 0% 5%;
  width: 100%;
  height: 100%;
}

.Carousel.container {
  container-name: carousel-container;
  container-type:normal
}

.Contact-form {
  background-color: seashell;
  font-size: calc(10px + 2vmin);
  font-family:'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  align-content: left;
  padding: 2% 2% 2% 2%;
}

.Contact-form.title {
  align-content: center;
}

.Tab {
  border-color:lime;
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255, 251, 0, 0.868);
}
 
.tab-content.background {
  background-color: rgba(0, 174, 255, 0.243);
}