input:invalid {
    border: 2px dashed red;
  }

  input:required {
    background-image: linear-gradient(to right, pink, lightgreen);
  }

  input:valid {
    border: 2px solid black;
  }

  captcha-float {
      float:left;
  }

  button-float {
      float:right
  }